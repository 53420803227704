<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Name <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500">Slug</label>
                <b-form-input
                  :readonly="$route.name === 'edit-product'"
                  v-model="form.slug"
                  id="input-1"
                  placeholder="Enter Slug"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Type <span style="color: red">*</span></label
                >

                <b-form-select
                  id="type"
                  v-model="form.type"
                  :class="{
                    'is-invalid': submitted && $v.form.type.$error,
                  }"
                >
                  <option value="1">Fixed</option>
                  <option value="2">Subscribed</option>
                  <div
                    v-if="submitted && !$v.form.type.required"
                    class="invalid-feedback"
                  >
                    Type is required.
                  </div>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >URL <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter URL"
                  v-model="form.url"
                  :class="{
                    'is-invalid': submitted && $v.form.url.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.url.required"
                  class="invalid-feedback"
                >
                  URL is required.
                </div>
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-product'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import productEmail from "../../../mixins/ModuleJs/product";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, productEmail],
  data() {
    return {
      submitted: false,
      title: "Product",
      items: [
        {
          text: "Back",
          href: "/product",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      type: { required },
      url: { required },
    },
  },
};
</script>
