var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"email",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Slug")]),_c('b-form-input',{attrs:{"readonly":_vm.$route.name === 'edit-product',"id":"input-1","placeholder":"Enter Slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.type.$error,
                },attrs:{"id":"type"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Fixed")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Subscribed")]),(_vm.submitted && !_vm.$v.form.type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Type is required. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("URL "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.url.$error,
                },attrs:{"id":"input-1","placeholder":"Enter URL"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),(_vm.submitted && !_vm.$v.form.url.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" URL is required. ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-product')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }